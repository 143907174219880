<template>
  <BlankLayout>
    <v-layout align-center justify-center>
      <div>
        <div style="text-align: center">
          <img
            src="@/assets/logo.png"
            width="200"
            style="padding-bottom: 20px"
          />
        </div>
        <SwitchDevicePrompt
          style="text-align: center"
          v-if="switchDevicePrompt"
          @no="userDoesNotWantToLoginHere"
          @yes="submitPin(false)"
        ></SwitchDevicePrompt>
        <v-card flex md8 sm8 xs12 class="elevation-12" v-else>
          <v-card-subtitle v-if="event_client !== ''">
            {{ event_client }}
          </v-card-subtitle>

          <v-card-title v-if="event_name !== ''">
            {{ event_name }}
          </v-card-title>

          <v-card-text>
            <p>
              {{ $t('views.tfa.hello') }}
            </p>

            <p>
              {{ $t('views.tfa.pleasePrepareMobileDevice') }}
            </p>

            <p>
              {{ $t('views.tfa.pinToMobile', { value: last_four_digits })
              }}<br />
              {{ $t('views.tfa.pinValid') }}
            </p>

            <p class="mb-10" v-if="event_contact !== ''">
              {{
                $t('views.tfa.helpContact', {
                  value: event_contact,
                })
              }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-btn v-if="pinSent === false" color="primary" @click="sendPin">
              {{ $t('views.tfa.sendPin') }}
            </v-btn>
            <div v-else-if="pinSent === true && sms_lock === false">
              <v-text-field
                v-model="pin"
                append-outer-icon="mdi-send"
                :rules="[
                  v => !!v || $t('views.tfa.validation.required'),
                  v =>
                    (v && v.length === 4) || $t('views.tfa.validation.length'),
                  v =>
                    Number.isInteger(Number.parseInt(v)) ||
                    $t('views.tfa.validation.number'),
                ]"
                clear-icon="mdi-close-circle"
                clearable
                :label="$t('views.tfa.enterPin')"
                type="text"
                ref="pin"
                :error-messages="pin_error_messages"
                @click:append-outer="submitPin"
                @click:clear="clearPin"
                @keyup.enter="submitPin"
                outlined
              ></v-text-field>
              <v-btn @click="sendPin" color="primary">
                {{ $t('views.tfa.pinNotReceivedOrExpired') }}
              </v-btn>
            </div>
            <v-alert
              border="top"
              color="error"
              dark
              style="width: 100%"
              v-else-if="
                pinSent === true && sms_lock === true && event_contact !== null
              "
            >
              <b>{{ $t('views.tfa.pinRequestLimit') }}</b
              ><br />{{ $t('views.tfa.talkToAdministrator') }}
              <a style="color: white" :href="'mailto:' + event_contact">{{
                event_contact
              }}</a
              >.
            </v-alert>
          </v-card-actions>
        </v-card>
      </div>
    </v-layout>
  </BlankLayout>
</template>

<script>
import BlankLayout from '@/components/layouts/Blank.vue'
import handle_errors from '@/api/handle_errors.js'
import handle_token_refresh from '@/api/handle_token_refresh.js'
import beforeunload from '@/helpers/beforeunload.js'
import SwitchDevicePrompt from '@/components/SwitchDevicePrompt.vue'

async function get_event_prologue(access_token) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/events/${access_token}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

async function send_pin_using_sms(access_token) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/send-sms/${access_token}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

async function loginTfa(access_token, pin, login_secret) {
  return await fetch(
    `${process.env.VUE_APP_API_URL}/voter/login-tfa/${access_token}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pin: pin,
        login_secret: login_secret,
      }),
    }
  )
}

export default {
  name: 'tfa',

  // this.$route.params.access_token
  props: {
    access_token: String,
  },

  components: {
    BlankLayout,
    SwitchDevicePrompt,
  },

  data() {
    return {
      title: this.$t('views.tfa.title'),
      pinSent: false,
      pin: '',
      // Add strings to this array to put the pin field in error state.
      pin_error_messages: [],

      // Obtained from event prologue.
      event_name: '',
      event_client: '',
      event_contact: '',
      last_four_digits: '****',
      sms_lock: false,

      switchDevicePrompt: false,
      login_secret: null,
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.title = this.$t('views.tfa.title')
      document.title = this.title
    },
  },

  created: function () {
    document.title = this.title
  },

  beforeRouteEnter: function (to, from, next) {
    get_event_prologue(to.params.access_token)
      .then(handle_token_refresh)
      .then(handle_errors)
      .then(data => {
        next(vm => {
          vm.event_name = data.data.name
          vm.event_client = data.data.client
          vm.event_contact = data.data.contact

          vm.$i18n.locale = data.data.language

          vm.last_four_digits = data.data.last_four_digits
          vm.sms_lock = data.data.sms_lock

          if (data.data.step === 2) {
            vm.pinSent = true
          }

          // If TFA is not enabled, redirect to Login.
          if (!data.data.sms_authentication) {
            vm.$router.replace({
              name: 'Login',
              params: { token: vm.access_token },
            })
          }
        })
      })
      .catch(response => {
        if (response.status === 401) {
          next(vm => {
            vm.$router.replace({
              name: 'Login',
              params: { token: vm.access_token },
            })
          })
        } else {
          next(vm => {
            vm.$router.replace({ name: 'Unauthorized' })
          })
        }

        console.error(response)
      })
  },

  methods: {
    userDoesNotWantToLoginHere() {
      this.pin_error_messages = [
        this.$t('components.switchDevicePrompt.doesNotWantToLogin'),
      ]

      this.login_secret = null

      this.switchDevicePrompt = false
    },

    sendPin() {
      send_pin_using_sms(this.access_token)
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          if (data.count_valid === false) {
            this.sms_lock = true
          }
        })
        .catch(response => {
          this.$router.replace({ name: 'Unauthorized' })

          console.error(response)
        })

      this.pinSent = true
    },

    submitPin(validate = true) {
      if (validate === true && !this.$refs.pin.validate(true)) {
        return
      }

      loginTfa(this.access_token, this.pin, this.login_secret)
        .then(handle_token_refresh)
        .then(handle_errors)
        .then(data => {
          this.$mystore.setEvent(data.data.event)
          this.$mystore.setLoggedIn(true)

          beforeunload.enable()

          localStorage.setItem('token', data.data.token)

          this.$router.replace({ name: 'Home' })
        })
        .catch(async response => {
          if (response.status === 409) {
            let data = await response.json()

            this.login_secret = data.data.login_secret

            this.switchDevicePrompt = true
          } else if (response.status === 403) {
            this.$router.replace({
              name: 'Login',
              params: { token: this.access_token },
            })
          } else if (response.message === 401) {
            this.pin_error_messages = [
              this.$t('views.tfa.pinNotValidOrExpired'),
            ]
          } else {
            this.$router.replace({ name: 'Unauthorized' })
          }

          console.error(response)
        })
    },

    clearPin() {
      this.pin = ''
    },
  },
}
</script>
