<template>
  <div class="mt-10 mb-5">
    <h1 style="white-space: pre-wrap">
      {{ $t('components.switchDevicePrompt.heading') }}
    </h1>
    <p style="white-space: pre-wrap">
      {{ $t('components.switchDevicePrompt.text') }}
    </p>
    <v-btn class="mx-2 error" @click="$emit('no')">{{
      $t('components.switchDevicePrompt.no')
    }}</v-btn>
    <v-btn class="mx-2 success" @click="$emit('yes')">{{
      $t('components.switchDevicePrompt.yes')
    }}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'SwitchDevicePrompt',
}
</script>
